<template>
  <div>
    <div class="d-flex justify-content-end mt-0" />
    <DeleteUser
      :id="deleteUserId"
      ref="delete-user-modal"
      @getAllUsers="getAllUsers"
    />
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="paginationData.perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="paginationData.perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
              @input="paginationChanged(paginationData.currentPage = 1)"
            />
            Users
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
                @keyup.enter="paginationChanged(1)"
                @change="paginationData.currentPage = 1"
              />
              <b-button
                variant="primary"
                @click="paginationChanged(1)"
              >
                Search
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-skeleton-table
        v-if="spinner"
        :rows="paginationData.perPage"
        :columns="5"
        :table-props="{ bordered: true, striped: true, tableClass:'skeleton-loading-table' }"
      />
      <b-table
        v-else
        id="allUsers-table"
        hover
        selectable
        :per-page="paginationData.perPage"
        :select-mode="selectMode"
        :items="allUsers"
        responsive
        :fields="tableColumns"
        show-empty
        empty-text="No matching records found"
        class="position-relative"
        :sort-by.sync="sortBy"
        @row-selected="onRowSelected"
      >
        <!-- Column: id -->
        <template #cell(ID)="data">
          <b-link
            :to="{ name: 'user', params: { id: data.item.id } }"
          >
            <span class="text-nowrap">
              {{ data.item.id }}
            </span>
          </b-link>
        </template>
        <!-- Column: User -->
        <template #cell(USER)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.image"
                :to="{ name: 'user', params: { id: data.item.id } }"
              />
            </template>
            <span
              :to="{ name: 'user', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </span>
            <small class="text-muted text-nowrap">@{{ data.item.phone_number }}</small>
          </b-media>
        </template>

        <!-- Column: Email -->
        <template #cell(EMAIL)="data">
          <span class="text-nowrap">
            {{ data.item.email }}
          </span>
        </template>

        <!-- Column: Status -->
        <template #cell(STATUS)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.account_status)}`"
            class="text-capitalize"
          >
            {{ data.item.account_status === ActiveType.ACTIVE ? 'Active': 'Inactive' }}
          </b-badge>
        </template>
        <!-- Column: Actions -->
        <template #cell(ACTIONS)="data">
          <div class="text-nowrap float-center ml-4">
            <b-img
              :id="`invoice-row-${data.item.id}-send-icon`"
              :src="iconFunc(data.item.account_status)"
              :variant="`light-${resolveUserStatusVariant(data.item.account_status)}`"
              class="cursor-pointer"
              size="16"
              @click="deleteModal($event)"
            />
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span
              class="text-muted"
            /></b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              :value="paginationData.currentPage"
              :total-rows="paginationData.totalRecords"
              :per-page="paginationData.perPage"
              class="mb-0 mt-1 mt-sm-0"
              aria-controls="allUsers-table"
              size="sm"
              pills
              @change="paginationChanged"
            />
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BPagination,
  VBToggle,
  BAvatar,
  BBadge,
  BImg,
  BSkeletonTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Vue from 'vue'
import Ripple from 'vue-ripple-directive'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import ActiveType from '@/common/enums/accountStatusEnum'
import DeleteUser from '@/components/user/DeleteUser.vue'

Vue.use(Vuesax)
Vue.directive('ripple', Ripple)
export default {
  components: {
    DeleteUser,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BAvatar,
    BBadge,
    BImg,
    BSkeletonTable,
    BPagination,

    vSelect,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      ActiveType,
      active: false,
      sortBy: 'STATUS',
      searchQuery: '',
      spinner: false,
      allUsers: [],
      deleteUserId: null,
      paginationData: {
        perPageOptions: [3, 5, 8, 10],
        currentPage: 1,
        perPage: 5,
        totalRecords: 0,
      },
      tableColumns: [
        { key: 'ID' },
        { key: 'USER' },
        { key: 'EMAIL' },
        { key: 'STATUS', sortable: true },
        {
          key: 'ACTIONS',
          style: 'float: right',
          thClass: 'pull-center text-center textTransform',
        },
      ],
      selectMode: 'single',
      selected: [],
    }
  },
  async mounted() {
    await this.getAllUsers()
  },
  methods: {
    async paginationChanged(offset) {
      this.paginationData.currentPage = offset
      await this.getAllUsers()
    },
    getAllUsers() {
      this.spinner = true
      this.$axios.get(`admin?offset=${this.paginationData.currentPage - 1}&limit=${this.paginationData.perPage}&search_query=${this.searchQuery}&sortDir=asc&sortBy=${this.sortBy}`)
        .then(({ data }) => {
          if (this.paginationData.currentPage > 1 && data.data.results.length === 0) {
            this.paginationChanged(this.paginationData.currentPage - 1)
            return
          }
          this.allUsers = data.data.results
          this.paginationData.totalRecords = data.data.total
          this.spinner = false
        }).catch(() => this.$swal('Users not found!'))
    },
    onRowSelected(user) {
      this.selected = user
      this.$router.push(`/users/${this.selected[0].id}`)
    },
    changeState() {
      this.active = false
    },
    resolveUserStatusVariant(Status) {
      if (Status === ActiveType.ACTIVE) return 'success'
      if (Status === ActiveType.INACTIVE) return 'secondary'
      return 'primary'
    },
    iconFunc(Status) {
      /* eslint-disable global-require */
      if (Status === ActiveType.ACTIVE) return require('@/assets/images/simiicons/Grey.svg')
      if (Status === ActiveType.INACTIVE) return require('@/assets/images/simiicons/Grey.svg')
      return require('@/assets/images/simiicons/Grey.svg')
    },
    deleteModal(e) {
      e.preventDefault()
      e.stopPropagation()
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.circular_image {
  border-radius: 40px;
  background-color: #eeedfd;
}
</style>
